<template>
	<v-sheet flat>
		<navigation></navigation>
		<v-card flat color="lightest" class="rounded-lg mb-4">
			<v-expansion-panels v-model="panel" flat class="transparent">
				<v-expansion-panel class="transparent">
					<v-expansion-panel-header>
						{{ panel == 0 ? "Hide filters" : "Show filters" }}
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-card-text>
							<filter-records v-model="filters"></filter-records>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="submit" color="primary">Update</v-btn>
						</v-card-actions>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel class="transparent">
					<v-expansion-panel-header>
						{{
							panel == 1
								? "Hide column settings"
								: "Show column settings"
						}}
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-row class="mb-3 pa-5">
							<v-checkbox
								hide-details
								v-for="column in columns"
								class="mr-4"
								:key="column.value"
								:label="column.text"
								v-model="column.display"
							></v-checkbox>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card>
		<v-data-table :headers="headers" :items="items">
			<template v-slot:item.actions="{ item }">
				<convert-submission-to-user v-if="!item.user" v-model="item.id"></convert-submission-to-user>
				<template v-else>
					<remind v-if="item.status =='contacted'" :value="item.user"></remind>
					<contact v-if="item.status == 'new'" :value="item.user" :submission="item.id"></contact>
				</template>
			</template>
			<template v-slot:item.submitted_at="{ item }">
				{{ item.created_at.toLocaleDateString() }}
			</template>
			<template v-slot:footer.prepend>
				<export
					:rows="items"
					:columns="headers"
                    name="Incentive Submissions"
					:disabled="!items.length"
				></export>
			</template>
		</v-data-table>
	</v-sheet>
</template>

<script>
import FilterRecords from "@c/incentives/Filter.vue";
import Export from "@c/downloads/Export";
import ConvertSubmissionToUser from "@c/incentives/ConvertSubmissionToUser";
import Navigation from "@c/incentives/Navigation";
import Remind from "@c/incentives/Remind";
import Contact from "@c/incentives/Contact";
export default {
	name: "IncentiveSubmissions",
	data: () => {
		return {
			panel: 0,
			filters: {
				created_by: [], 
				date_start: null, 
				date_end: null,
				projects: [], 
				statuses: [],
			},
			columns: [
				{ value: "name", text: "Name", display: true },
				{ value: "email", text: "Email", display: true },
				{ value: "amount", text: "Amount", display: true },
				{ value: "submitter", text: "Submitter", display: false },
				{ value: "submitted_at", text: "Submitted At", display: true },
				{ value: "sow", text: "Sow", display: false },
				{ value: "project", text: "Project", display: false },
				{ value: "status", text: "Status", display: true },
				{ value: "actions", text: "", display: true },
			]
		};
	},
	computed: {
        users() {
            return this.$store.state.users.data
        },

		records() {
			return this.$store.getters["incentiveSubmissions/getByFilters"](
				this.filters
			);
		},
		projects(){
			return this.$store.state.projects.data
		},
		items() {
            const self = this;
			return self.records.map( r => {
				let item = {...r};
                item.name = item.first_name + " " + item.last_name;
				item.created_at = item.created_at ? item.created_at.toDate() : "";
				item.project = self.projects[item.project] ? self.projects[item.project].internal_name : item.project;
                item.submitter = self.users[item.created_by] ? self.users[item.created_by].name : "";
                return item;
            })
		},
		headers(){
			return this.columns.filter( a => a.display );
		}
	},
	components: {
		FilterRecords,
        ConvertSubmissionToUser,
        Export,
		Navigation,
		Contact,
		Remind
	},
	methods: {
		submit() {
			this.$store.dispatch(
				"incentiveSubmissions/fetchByFilters",
				this.filters
			);
		},
	},
};
</script>
