<template>
	<send-email-button
		class="mb-5"
		@sent="markSent"
		email="incentive_request"
		:template="template"
		:values="{ user: value }"
	>
		<template v-slot:dialog-body>
			<v-select
				v-model="template"
				:items="templates"
				label="Template"
			></v-select>
		</template>

		<template v-slot:button="scope">
			<v-btn small @click="scope.click" color="primary">
				Contact
			</v-btn>
		</template>
	</send-email-button>
</template>

<script>
import SendEmailButton from "@c/communication/SendEmailButton";
export default {
	name: "Reminder",
	props: {
		value: { type: String },
		submission: { type: String },
	},
	data: () => {
		return {
			template: "dfe_rewards_request_email",
			templates: ["dfe_rewards_request_email"],
		};
	},
	components: {
		SendEmailButton,
	},
	methods: {
		markSent() {
			this.$store.dispatch("incentiveSubmissions/patch", {
				id: this.submission,
				status: "contacted",
			});
		},
	},
};
</script>
